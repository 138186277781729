<template>
  <div class="cont">
    <Header active_tab="mentorship"></Header>
    <h3 style="text-decoration: underline white">
      Timetable
      <span style="color: red">Report</span>
    </h3>
    <div class="sample-excel">
      <h5 style="order: 2; text-align: end" @click="downloadSampleExcel">
        Download Sample <img :src="img" alt="" height="16" width="16" />
      </h5>
      <transition name="fade">
        <StatsTimetable
          @hideModal="showModal = !showModal"
          v-if="showModal"
          :found="found"
          :date1="date1"
          :date2="date2"
          :img-list="imglist"
          :teacher_review="teacher_review"
          :review="isReviewed"
        ></StatsTimetable>
      </transition>
      <time-table-list-item
        v-for="(timetable, index) in timeTableData"
        :key="index"
        :index="index + 1"
        :from="timetable.date1"
        :to="timetable.date2"
        @click="openModal(index)"
        @downloadExcel="downloadExcel(index)"
        :is-reviewed="timetable.review"
        :review="timetable.teacher_review"
        :img="img"
      ></time-table-list-item>
    </div>
    <input
      type="file"
      ref="input1"
      @change="onFilePickup"
      id="file"
      style="display: none"
    />

    <div class="start-day">
      <span class="startend-text">Start Date - </span>
      <select class="select-button" v-model="day">
        <option v-for="(day, index) in dates" :key="index">{{ day }}</option>
      </select>
      <select class="select-button" v-model="month">
        <option v-for="(month, index) in months" :key="index">
          {{ month }}
        </option>
      </select>
      <select class="select-button" v-model="year">
        <option v-for="(year, index) in years" :key="index">{{ year }}</option>
      </select>
    </div>
    <div class="end-day">
      <span class="startend-text">End Date - </span>
      <select class="select-button" v-model="day1">
        <option v-for="(day, index) in dates" :key="index">{{ day }}</option>
      </select>
      <select class="select-button" v-model="month1">
        <option v-for="(month, index) in months" :key="index">
          {{ month }}
        </option>
      </select>
      <select class="select-button" v-model="year1">
        <option v-for="(year, index) in years" :key="index">{{ year }}</option>
      </select>
    </div>
    <div type="button" @click="onUpload" class="btn btn-success">
      Select and Upload
    </div>
    <small v-if="uploading">Uploading...</small>

    <button class="btn btn-dark" @click="openDoc">Open Google docs</button>
  </div>
</template>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Nunito");
@import url("https://fonts.googleapis.com/css?family=Kaushan+Script");
h3 {
  font-family: "Kaushan Script", "Lato", Helvetica, sans-serif;
  padding: 20px;
}
.startend-text {
  font-size: 20px;
  color: rgb(228, 229, 230);
}
.select-button {
  border: none;
  background-color: rgb(240, 240, 240);
  border-radius: 3px;
  outline: none;
  margin-left: 10px;
}
.select-button:hover {
  /* border-radius: 20px; */
  /* border-width: 5px; */
  /* border-color: aqua; */
}
h5 {
  font-size: 20px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;

  transition: opacity 0.5s;
}
.cont {
  min-height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  background: linear-gradient(
    330deg,
    rgba(235, 234, 240, 1) 0%,
    rgba(67, 41, 94, 1) 100%,
    rgba(251, 251, 251, 1) 100%
  );
  text-align: center;
  color: white;
  font-family: "Nunito", "Open Sans", sans-serif;
}
.btn {
  margin: 30px;
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  position: relative;
  width: 50%;
  max-height: 300px;
  padding: 16px;
  overflow: auto;
  background-color: #fff;
  border-radius: 4px;
}
.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 8px 8px 0 0;
  cursor: pointer;
}
.modal-close:hover {
  color: gray;
}
.sample-excel {
  width: max-content;
  margin: 0 auto;
  cursor: pointer;
  padding-top: 20px;
}

select {
  /*display: flex;*/
  flex-direction: column;
  position: relative;
  width: 80px;
  margin-top: 30px;
  height: 40px;
}

option {
  padding: 0 30px 0 10px;
  min-height: 40px;
  display: flex;
  align-items: center;
  border-top: #222 solid 1px;
  position: absolute;
  top: 0;
  width: 10%;
  pointer-events: none;
  order: 2;
  z-index: 1;
  transition: background 0.4s ease-in-out;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
}

select:focus option {
  position: relative;
  pointer-events: all;
}
</style>
<script>
import firebase from "firebase";
import db from "../firebase";
import axios from "axios";
import store from "../store";

const Header = defineAsyncComponent(() => import("@/components/Header"));
const StatsTimetable = defineAsyncComponent(() =>
  import("@/components/StatsTimetable")
);
const TimeTableListItem = defineAsyncComponent(() =>
  import("@/components/TimeTableListItem")
);
import { getGoogleDocLinks, getUserDetails } from "@/Utils/global";
import { defineAsyncComponent } from "vue";

export default {
  name: "Reports",
  components: { TimeTableListItem, StatsTimetable, Header },
  data() {
    return {
      found: false,
      review: false,
      teacher_review: "",
      date1: "",
      date2: "",
      day: 27,
      month: 10,
      day1: 29,
      month1: 10,
      year: 2021,
      year1: 2021,
      dates: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      years: [
        2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011,
        2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023,
        2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035,
        2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047,
        2048, 2049, 2050,
      ],
      uploading: false,

      imglist: [],
      timeTableData: [],

      showModal: false,
      isReviewed: false,
      img: require("/src/assets/download.svg"),
      docLink: "www.google.com",
    };
  },

  async mounted() {
    getUserDetails(store.getters.user.data.uid).then((doc) => {
      if (doc.exists) {
        var di = doc.data();
        console.log(di.timetable);

        //Doc will exist only if there is some timetable previously set
        this.found = true;
        for (let j = 0; j < di.timetable.length; j++) {
          console.log(di.timetable[j]);
          let oneTimeTable = di.timetable[j];

          let timeTableObject = {
            date1: oneTimeTable["time_table_date1"],
            date2: oneTimeTable["time_table_date2"],
            review: oneTimeTable["timetable_is_reviewed"],
            teacher_review: oneTimeTable["review"],
            timeStamp: oneTimeTable["timeStamp"],
          };

          var excelFileLink = firebase
            .storage()
            .ref()
            .child(
              "timetable/" +
                store.getters.user.data.uid +
                "/" +
                timeTableObject.timeStamp +
                "/" +
                timeTableObject.timeStamp +
                ".xlsx"
            )
            .getDownloadURL()
            .then((url) => {
              timeTableObject["excel"] = url;
            });

          (async () => {
            var imgs = [];
            for (let i = 0; i < 5; ++i) {
              var url0 = await this.getUrl(i, timeTableObject.timeStamp);
              imgs.push(url0);
            }
            timeTableObject["imgList"] = imgs;
            this.timeTableData.push(timeTableObject);
          })();
        }
      } else {
        //Leave as it is
      }
    });

    await getGoogleDocLinks(store.getters.user.data.uid).then((doc) => {
      if (doc.exists) this.docLink = doc.data().link;
    });

    store.state.loading = false;
  },
  methods: {
    openDoc() {
      window.open(this.docLink, "_blank");
    },
    hello() {
      console.log(this.year);
      console.log(this.year1);
    },
    downloadExcel(index) {
      window.open(this.timeTableData[index].excel);
    },
    openModal(index) {
      this.date1 = this.timeTableData[index].date1;
      this.date2 = this.timeTableData[index].date2;
      this.teacher_review = this.timeTableData[index].teacher_review;
      this.imglist = this.timeTableData[index].imgList;
      this.showModal = true;
      this.isReviewed = this.timeTableData[index].review;
      this.teacher_review = this.timeTableData[index].teacher_review;
    },
    onUpload() {
      this.$refs.input1.click();
    },
    onFilePickup(event) {
      const file = event.target.files[0];
      this.fireStoreUpload(file);
      event.target.files = null;
    },
    fireStoreUpload(file) {
      this.uploading = true;
      let milliseconds = new Date().getTime();
      const storageRef = firebase
        .storage()
        .ref(
          "timetable/" +
            store.getters.user.data.uid +
            `/${milliseconds}/${milliseconds}.xlsx`
        )
        .put(file)
        .then(() => {
          //Call our server to do some work
          console.log(
            "timetable/" +
              store.getters.user.data.uid +
              `${milliseconds}/${milliseconds}.xlsx`
          );
          console.log("uploaded");
          var config = {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          };

          axios
            .post(
              "https://Pysberduck.pythonanywhere.com/api/timetable",
              {
                user_id: store.getters.user.data.uid,
                username: store.getters.user.data.username,
                day: +this.day,
                month: +this.month,
                year: +this.year,
                year1: +this.year1,
                day1: +this.day1,
                month1: +this.month1,
                fileName: milliseconds.toString(),
              },
              config
            )
            .then(
              (res) => {
                this.uploading = false;
                res;

                let timetable = [
                  {
                    time_table_date1:
                      this.day + "/" + this.month + "/" + this.year,
                    time_table_date2:
                      this.day1 + "/" + this.month1 + "/" + this.year1,
                    timetable_is_reviewed: false,
                    review: "",
                    timeStamp: milliseconds,
                  },
                ];
                if (res.data == "200") {
                  //One time table upload set its record in firestore
                  db.collection("user_details")
                    .doc(store.getters.user.data.uid)
                    .set(
                      {
                        timetable: firebase.firestore.FieldValue.arrayUnion(
                          ...timetable
                        ),
                      },
                      { merge: true }
                    )
                    .then(() => location.reload());
                } else if (res.data == "500") {
                  swal("Not a valid timetable");
                } else swal("Some error occured");
              },
              (err) => {
                this.uploading = false;
                swal("Some error occured");
              }
            );
        });
    },
    downloadSampleExcel() {
      window.open(
        "https://firebasestorage.googleapis.com/v0/b/psyberduck-fccf1.appspot.com/o/timetable%2FSample%2Fsample.xlsx?alt=media&token=7d7526a7-5dc8-4aae-9626-4c5a5bdaef59"
      );
    },
    getUrl(index, time) {
      return firebase
        .storage()
        .ref()
        .child(
          "timetable/" +
            store.getters.user.data.uid +
            "/" +
            time +
            "/img" +
            index +
            ".png"
        )
        .getDownloadURL();
    },
  },
};
</script>
